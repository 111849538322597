.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main {
  margin: 5px;
  padding: 5px;
  max-height: 90vh;
  overflow: auto;
}

.landingMain {
  display: flex;
  justify-content: space-around;
}

.quantity-display {
  display: inline-block;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.animate-up {
  animation: slide-down 0.3s ease-in-out;
}

.animate-down {
  animation: slide-up 0.3s ease-in-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.css-b62m3t-container {
  border-radius: 4px !important;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.profileView {
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 50% !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

li.ps-menuitem-root li.ps-menuitem-root {
  background-color: #154360;
  color: white !important;
  /* min-height: 15vh; */
  /* Submenu background color*/

}

.css-1ffbfs a.ps-menu-label {
  color: #00ff00 !important;
}

/* .ps-menuitem-root .ps-submenu-root a .ps-menu-icon :hover {
  color: #fff;
}

.ps-menuitem-root .ps-submenu-root a .ps-menu-icon {
  color: white;
} */

.css-1ffbfs>.ps-menu-button {
  margin-left: 40px !important;
  padding-left: 0 !important;
  height: 45px !important;
}

.css-8mnv9e>.ps-menu-button {
  padding-left: 10px !important;
  height: 47px !important;
}


.css-x3jz15>.ps-menu-button {
  margin-left: 75px !important;
  padding-left: 0px !important;
  height: 40px !important;
  padding-right: 2px !important;
}

/* .css-x3jz15>.ps-menu-button {
  padding-left: 0px !important;
} */


.css-7wwmzq>.ps-menu-button {
  padding-left: 10px !important;
  height: 47px !important;
}

.css-1ffbfs>.ps-menu-button.ps-menu-icon:hover {
  color: #000 !important;
}

.css-1ffbfs>.ps-menu-button.ps-menu-icon {
  color: #fff !important;
}

.ps-submenu-content {
  background-color: #154360 !important;

}

.logout {
  padding: 5px 15px;
  border-radius: 10px;
  background-color: white;
  color: #154360;
  font-weight: 600;
}

.css-pjtha9 {
  border-color: transparent !important;
}

.css-z5rm242 {
  background-color: #154360 !important;
}

.css-1791xpl {
  border: none !important;
}

.ps-menuitem-root.active a {
  color: #000;
  background-color: #fff;

}


.ps-menuitem-root.active a {
  color: #000;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px !important;

}

.css-1tqrhto>.ps-menu-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 45px !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 7px !important;
  padding-left: 0px !important;
  margin-left: 40px !important;
  border-radius: 30px 0px 0px 30px;

}

.ps-menuitem-root a:hover {
  /* Hoverd menu*/
  color: #000;
  background-color: #fff !important;

  border-radius: 20px 20px 20px 20px;


}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.active :hover {
  /* background-color: #4d6c80; */
  /* border-radius: 10px; */
  color: black;
}

.active1 {
  background-color: #4d6c80;
  border-radius: 10px;
  color: black;
  font-size: 1rem;
  font-weight: bold;
}

.active2 {
  background-color: #4d6c80;
  border-radius: 10px;
  color: #131313;
  font-size: 0.9rem;
  font-weight: bold;
}

.addbtn {
  background-color: #154360;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 7px 10px;
  cursor: pointer;
}

.landButton {
  background-color: #fff;
  font-size: 20px;
  border: none;
  color: #154360;
  border-radius: 5px;
  padding: 5px 13px;
  cursor: pointer;
  border: 2px solid #154360;

}

.landButton:hover {
  background-color: #154360;
  font-size: 20px;

  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 13px;

  cursor: pointer;
  border: 2px solid #154360;
}


.cancelbtn {
  background-color: #ea9598;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 7px 10px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  /* Sit on top of the page content */
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 100;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.bt {
  background-color: #dddcdd;
  color: #131313;

  font-weight: bold;
  font-size: 20px;

  border-radius: 5px;
  --bs-accordion-active-bg: #e7e8eb;
  --bs-accordion-btn: #edabed;
  --bs-accordion-btn-focus-border-color: #e7e8eb;
  --bs-accordion-btn-focus-box-: #dddcdd;
}

/* .addbtn {
  background-color: #C5F6E2;
  color: black;
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #223947;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #223947;
}

/* .{
  background-color: rgb(246, 244, 244);
  
} */
.button {
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 15px;
  background-color: red;
  border-radius: 10px;
  border: none;
  color: white;
  position: relative;
  cursor: pointer;
  font-weight: 900;
  transition-duration: 0.2s;
  background: "white";
  background: linear-gradient(0deg, #000, #272727);
}

.button:before,
.button:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 10px;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

.marginright {
  margin-right: 10px;
  margin-left: 5px;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button:after {
  filter: blur(50px);
}

.wrapper {
  padding: 4em;
  padding-bottom: 0;
}

.currency-selector {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: 0.5rem;
  border: 0;
  background: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1024' height='640'><path d='M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z'></path></svg>") 90%/12px 6px no-repeat;

  font-family: inherit;
  color: inherit;
}

.currency-amount {
  text-align: right;
}

.currency-addon {
  width: 6em;
  text-align: left;
  position: relative;
}

.my-multi-lines-item {
  font-size: 14px;
  white-space: initial;
  display: flex;
  height: auto;
  line-height: normal;
  padding: 10px;
}

.btnPlus {
  background-color: #88D5FF;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.btn- {
  background-color: #FF9688;
  padding: 6px 13px;
  cursor: pointer;
  border-radius: 4px;
  border: none;

}

.my-multi-lines-text {
  margin-top: auto;
  margin-bottom: auto;
}

.doc-target {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #000;
  line-height: 1.6em;
  margin: 0 auto;
}

.outer {
  /* padding: 72pt 72pt 72pt 72pt; */
  border: 1px solid #000;
  margin: 0 auto;
  width: 550px;
}


/* table {

  border-collapse: collapse;
  width: 100%;

} */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

}

tr {
  border: 1px solid #d7d0d0;

}

td,
th {
  /* border: 1px solid #dddddd; */
  text-align: justify;
  padding: 8px;
  background-color: #fff;
  color: #000;
}

th {
  background-color: #fff;
  color: #000;
}





.chatbot-button {
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: #154360;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}

/* cahtbot */
.chat-container {
  background-color: white;
  /* width: 400px; */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  /* height: 500px; */
  position: relative;
  overflow: scroll;

}


.maincontnt {
  position: absolute;
  margin-bottom: 500px;
  width: auto;
  margin-right: 500px;
  padding: 0px 0px;
}

.message-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;
  /* Adjust as needed */
  word-wrap: break-word;
}

.bot-message {
  width: auto;
  border: 1px solid #ccc;
  word-wrap: break-word;
  background-color: #f0f0f0;
  color: #333;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 8px;
}

.user-message {
  width: auto;
  border: 1px solid #ccc;
  word-wrap: break-word;
  background-color: #b9e1fa;
  color: #090909;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.message-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.message-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.message-input button {
  padding: 8px 12px;
  border: none;
  background-color: #f0f2f3;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-: inset 0 0 5px white;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: white;
}

.bg {
  background-color: #b9e1fa;
}

.myDIV:hover+.hide {
  display: block;
}

.hide {
  display: none;
}

.hide:hover {
  display: block;
}

.toast-position {
  top: 8rem !important;
}

.language-buttons {
  height: 30px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: start;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.star {
  font-size: 24px;
  cursor: pointer;
}

.filled {
  color: #f39701;
}

.yellow {
  color: yellow;
}



.custom-input {
  border-radius: 15px;
  border: 1px #0c8af8 solid;
  padding: 4px 12px;
  background-color: white;
  height: 22px;
  box-shadow: 0 0 2px #0074d9;
  width: 100%;
}

/* addbtnfunctionality start */
.addfc {
  background-color: #154360;
  /* border: solid 2px #b6dcf9; */
  color: #fff;
  border-radius: 5px;

}

.addfc {
  cursor: pointer;
}

/* addbtnfunctionality end */

.appointment .text {
  font-size: 15px;
  padding: 15px 10px 15px 10px;
}


.mobilepagination {
  display: none;
}

.pointer {
  cursor: pointer;
}

.main-content {
  display: flex;
  height: 100%;
  min-height: calc(100vh - 82px);
}

.content-section {
  width: 100%;

}

.leftMenuTogggle {
  position: absolute;
  top: 12px;
  left: 50px;
  color: #fff;
}

a.ps-menu-button:hover {
  color: #000;
}



.ps-menuitem-root.active a {
  color: #000;
}

@media(min-height:768) {
  .sidenavs {
    max-height: 100vh;
    overflow-y: scroll;
    width: "auto";

  }
}

@media(max-height:768) {
  .sidenavs {
    max-height: 100vh;
    overflow-y: scroll;
    width: "auto";


  }
}

.navbar-expanded {
  width: 150px;
}

.navbar-collapsed {
  width: 150px;
}

.sidebar {
  transition: width 0.3s ease-in-out;
}

.pro-sidebar {
  width: 250px;
  /* Change this value to your desired width */
}

.textWrap {
  text-wrap: nowrap
}

.bgHover {
  background-color: #abbbc6;
}

.container {
  text-align: center;
  margin-top: 20px;
}

.dropdown-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  top: 50px;
  background-color: #f1f1f1;
  margin-right: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.dropdown-menu a:hover {
  background-color: #ddd;
}

.responsiveTable th,
.responsiveTable td {
  word-wrap: break-word;
  white-space: normal;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 10px;
}

.responsiveTable th {
  background-color: #d4e6f2;
}

table tr {
  border: none;
}

@media(max-Width:768) {
  .tableresponsive {
    max-width: 50px;
  }

}

@media(min-Width:769px) {
  .tableresponsive {
    max-width: 100px;
  }

}

@media(min-Width:769px) {
  .addappointweb {
    display: block;
  }

  .addappointmentmobile {
    display: none;
  }

  .mobileIcon {
    min-width: 90px;
  }
}

.signature15vh {
  height: 15vh;
}

.css-wv3bts>.ps-menu-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 0px;
  color: #000;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-4 {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: none !important;
    border-bottom: 1px solid #eee !important;
    padding: 0.25em;
  }
}


.dateBorder {
  border-bottom: 1px solid #efefef;
}

.ep-arrow.p-5 {
  padding: 0 !important;
}

.rmdp-container {
  width: 100%;
}

.close-page-content {
  display: none !important;
}

.previousAppointments {

  font-weight: 500;
  font-size: 18px;
  align-items: center;
}

.borderBottomPatient {
  border-bottom: solid 1px #eeee;
  margin-right: 10px;
  margin-left: 10px;
}

.dotterLine {
  border-bottom: 0.5rem dotted #d8EFFF;
}

.card-container {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-container:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
}


.tableBorder {
  border: 2px solid rgb(122, 122, 122);



}

.leaflet-container {
  height: 426px;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
  height: 36px !important;
  width: 36px !important;
}

.menu-icon {
  display: none;
}




.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 992px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

}

@media (max-width: 992px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #f6f8fa;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 999;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

.tableHead {
  background-color: #00ff00 !important;
  border: 1px solid black !important;
  position: sticky;
}


/* cahtbot */
.chat-container {
  background-color: white;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 500px;
  position: relative;
  overflow: scroll;
}

.maincontnt {

  position: absolute;
  margin-bottom: 500px;
  width: auto;
  margin-right: 500px;
  padding: 0px 0px;
}

.message-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;
  /* Adjust as needed */
  word-wrap: break-word;
}

.bot-message {
  width: auto;
  border: 1px solid #ccc;
  word-wrap: break-word;
  background-color: #f0f0f0;
  color: #333;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 8px;
}

.user-message {
  width: auto;
  border: 1px solid #ccc;
  word-wrap: break-word;
  background-color: #b9e1fa;
  color: #090909;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.message-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.message-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.message-input button {
  padding: 8px 12px;
  border: none;
  background-color: #f0f2f3;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.tab-btn {
 
  color: black;
  border-radius: 5px;
  padding: 10px 15px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  
}

.tab-btn:hover {
  background-color: #154360;
  color: white;
  transform: scale(1.05);

}

.tab-btn.active {
  background-color: #154360;
  border-color: #154360;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.physianbackground {
  background-image: url(".././src/Images/physicianimg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  object-fit: contain;
}

.hhhh:hover {
  color: #7eb3d3;
}

.hover-effect {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hover-effect:hover {
  background-color: #7eb3d3;
  color: white;
  padding: 2px;

}

/* Custom style for outlined buttons with the same height and width */
.custom-button {
  border: 2px solid #154360;
  /* Outline color */
  background-color: transparent;
  color: #007bff;
  /* Text color */
  padding: 15px 30px;
  /* Adjust padding as needed */
  width: 150px;
  /* Set a fixed width for consistency */
  height: 60px;
  /* Set a fixed height for consistency */
  text-align: center;
  display: inline-block;
}

.custom-button:hover {
  background-color: #154360;
  color: white;
  cursor: pointer;
}

.datewidth {
  width: 200%;
  padding: 110px;
}

.datewidthRegister {
  width: 118%;
  padding: 110px;
  text-align: start;
}

.uniform-width {
  width: 100%;
  max-width: 400px; /* Adjust this value as needed for consistent width */
}
.uniform-widths {
  width: 100%;
  max-width: 600px; /* Adjust this value as needed for consistent width */
}

.header {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px 15px;
  gap: 10px;
}
.header-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.content {
  display: flex;
  gap: 7px;
  background-color: #eaf4ff;
  padding: 15px;
}
.sidebar {
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
}
.patient-details {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  align-items: center;
}
.time-details div {
  margin: 10px 0;
}
.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
 }
.parent-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #154360;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 175px;
}
.parent-button.active {
  background-color: #154360;
  color: #fff;
}


.sub-button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}
.sub-button {
  padding: 8px 12px;
  background-color: #fff;
  border: 1px solid #154360;
  border-radius: 20px;
  width: 80%; /* Reduce width compared to parent buttons */
  max-width: 200px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  margin: 0 auto;
}
.sub-button.active {
  background-color: #adc6d6;
  border: 1px solid #adc6d6
  
}

.main-content {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
}
.inputstyle{
  outline: 0;
            border-width: 0 0 2px;
            border-color: black;
}
.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px; 
}

.form-groupsa {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px; 
  width: 200%; 
  max-width: 400px; 
}
.form-groups {
  
  align-items: center;
  justify-content: flex-start;
  gap: 15px; 
  width: 200%; 
  max-width: 400px; 
}

/* .form-label {
  width: 220px; 
  text-align: end;
  font-size: medium; 
} */

 
.form-span {
  white-space: nowrap; 
}
.p-11 {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Space between the form groups */
  width: 100%; /* Full width of the parent container */
}

.form-groups {
  display: flex;
  flex-direction: column; /* Stack label and textarea */
  align-items: flex-start; /* Ensure both label and textarea start at the same line */
  gap: 2px; /* Space between label and textarea */
  width: 100%;
}

.form-labels {
  margin-bottom: 4px; /* Ensures the label starts consistently */
  text-align: left; /* Align text to the left */
  width: 100%; /* Makes the label stretch to align with the textarea */
}

.form-textarea {
  width: 190%; /* Full width of the parent container */
  height: 100px; /* Default height for textarea */
  padding: 8px; /* Add padding inside the textarea */
  border: 1px solid #ccc; /* Optional: Adds a border */
  border-radius: 4px; /* Optional: Rounded corners */
  resize: vertical; /* Allow vertical resizing, prevent horizontal resizing */
}
.form-textareas {
  width: 150%; /* Full width of the parent container */
  height: 100px; /* Default height for textarea */
  padding: 8px; /* Add padding inside the textarea */
  border: 1px solid #ccc; /* Optional: Adds a border */
  border-radius: 4px; /* Optional: Rounded corners */
  resize: vertical; /* Allow vertical resizing, prevent horizontal resizing */
}

.pain-level{
  height: 30px;
  text-align: center;
}
.selected{
  border: 1px solid black;
}
@media print {
  body {
    margin: 0;
    padding: 0;
  }

  #print-section {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 12px;
  }

  .details-table {
    width: 100%;
    border-collapse: collapse;
  }

  .details-table th,
  .details-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }

  .text-center, button {
    display: none; /* Hide buttons and unnecessary elements */
  }
  .sub-button-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
  }
  .sub-button {
    padding: 8px 12px;
    background-color: #fff;
    border: 1px solid #d4e6f2;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .sub-button:hover {
    background-color: #d4e6f2;
    color: #fff;
  }
  .sub-button.active-sub {
    background-color: #007bff;
    color: #fff;
  }

}

 
.calendar_default_event_inner.event.event-booked {

  background: red !important; 
  color: white !important; 
  border-radius: 4px;  
  border: 5px solid darkred !important;  
}
 .event-booked {
  background-color: red !important;
}

 .Booked {
  position: absolute;
  width: 6px;
  background-color: rgb(225, 13, 13)!important;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}
th, td {
  /* border: 1px solid #000; */
  text-align: center;
  padding: 8px;
}
th {
  background-color: #f2f2f2;
}
.header-cell {
  text-align: left;
  font-weight: bold;
}
.minHeightforPwa {
  min-height: 75vh;
}
.marginMain {
  margin: 10px 30px;
}
.profile-widget {
  background-color: #F4F4F4;
  /* border: 1px solid #E9E9EA; */
  border-radius: 10px;
  margin-bottom: 30px;
  min-height: 5vw;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.test-group-container {
  background-color: #F4F4F4;
  border: 1px solid #E9E9EA;
  display: flex;
  flex-wrap: wrap;
  gap: 16px; 
  justify-content: center; /* Center align rows */
  padding: 16px;
}

.test-box {
  width: calc(33.33% - 32px); /* Ensure three boxes fit in a row */
  max-width: 290px; /* Optional: Set a maximum width for larger screens */
  height: 250px; /* Fixed height */
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.test-group-header {
 
  font-size: 1.2rem;
 height: 10vh;
 background-color: #154360;
 color: white;
  
}

.test-group-names {
  padding: 8px;
  display: flex;  
  flex-wrap: wrap; 
  align-items: flex-start;  
  justify-content: flex-start;  
  text-align: left;  
  white-space: normal;  
  overflow: hidden;  
  word-wrap: break-word;  
  height: 100%;  
  overflow-y: auto;
}




.test-name-item {
  font-size: 1rem;
   color: #555;
}

.test-box::-webkit-scrollbar {
  width: 8px;
}

.test-box::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* .addbtn {
  cursor: pointer;
  background: #007bff;
  color: white;
  border-radius: 4px;
} */
.bed-box {
  padding: 8px;
  border: 1px solid #007BFF;
  border-radius: 4px;
  background: #f0f8ff;
  text-align: center;
  min-width: 50px;
  margin: 5px;
}
.hello {
  color: #fff;
  outline: 0;
  border: 0;
  padding: 8px 30px;
  border-radius: 24px;
  cursor: pointer;
  opacity: .9;
  background-image: linear-gradient(95deg, #53b2fe, #065af3);
}




.table-container {
  height: 70vh;
  overflow-x: auto;
  overflow-y: scroll;
}

.table-header, .table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.table-header {
  background-color: #154360;
  color: white;
}

.table-alt {
  background-color: #f3faff;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #154360;
  color: white;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 5px;
}

.search-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

@media (max-width: 768px) {
  .table-header, .table-row {
    flex-direction: column;
  }
}
@media (max-width: 561px) {
  .loginimage {
display: none;  }
}
.tab-name {
  position: relative;
  font-weight: bold;
  padding: 10px 0;
  display: inline-block;
}

.tab-name.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Matches text width */
  height: 3px;
  background-color: #007bff; /* Adjust color as needed */
}


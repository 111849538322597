.message {
    display: flex;
    margin-bottom: 10px;
}

.sender {
    justify-content: flex-end;
    /* Sender's messages go to the right */
}

.receiver {
    justify-content: flex-start;
    /* Receiver's messages go to the left */
}

.chat-bubble {
    min-width: 60%;
    padding: 10px;
    border-radius: 10px;
    position: relative;
}

.right {
    background-color: #daf8cb;
    align-self: flex-end;
    border-top-right-radius: 0;
}

.left {
    background-color: #e5e5e5;
    align-self: flex-start;
    border-top-left-radius: 0;
}

.message-info {
    display: flex;
    justify-content: end;
    margin-top: 5px;
    font-size: 0.8rem;
}

.sender-name {
    font-weight: bold;
}

.timestamp {
    color: gray;
}

.card {
    border: none !important;
}